<template>
  <div>
    <el-card>
      <div slot="header" class="clearfix">
        <span>更新公告</span>
        <el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh" @click="getLastNoticeList">
          刷新
        </el-button>
      </div>
      <div v-loading="loading">
        <div v-for="(item ,index) in noticeList" :key="index" style="color: #303133" class="text item">
          <span>{{ index + 1 }}、</span>
          <span> {{ item.notice_content }}</span>
          <span style="font-size: 0.8em;font-weight: 400;color: #909399;margin-left: 5px">[{{ item.created_at }}]</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "NoticePanel",
  data() {
    return {
      loading: false,
      noticeList: []
    }
  },
  methods: {
    /** 查询公告列表 */
    async getLastNoticeList() {
      this.loading = true
      let {list} = await this.$api.getNoticeData({})

      this.$nextTick(() => {
        this.noticeList = list
      })
      this.loading = false
    },
  },
  created() {
    this.getLastNoticeList()
  }
}
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}
</style>