<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <panel-group @handleSetLineChartData="handleSetLineChartData"/>

        <el-row :gutter="15">
          <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
            <el-card>
              <div slot="header" class="clearfix">
                <span>今日业务数据</span>
                <el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-refresh"
                           @click="loadTodayDateGrowth">刷新
                </el-button>
              </div>
              <div v-loading="loading">
                <el-form>
                  <!--                  <el-form-item label="钉钉用户：">-->
                  <!--                    新增：<span>{{ todayGrowthMap.ding_user_infos.today || 0 }}</span>条，-->
                  <!--                    总计：<span>{{ todayGrowthMap.ding_user_infos.all || 0 }}</span>条-->
                  <!--                  </el-form-item>-->

                  <el-form-item label="涨粉数据：">
                    新增：<span>{{ todayGrowthMap.trend_data?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.trend_data.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="营收数据：">
                    新增：<span>{{ todayGrowthMap.revenue_items?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.revenue_items.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="直播数据：">
                    新增：<span>{{ todayGrowthMap.live_infos?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.live_infos.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="店铺数据：">
                    新增：<span>{{ todayGrowthMap.shop_infos?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.shop_infos.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="档期数据：">
                    新增：<span>{{ todayGrowthMap.business_plans?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.business_plans.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="商务档期：">
                    新增：<span>{{ todayGrowthMap.business_plans?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.business_plans.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="视频案例：">
                    新增：<span>{{ todayGrowthMap.case_records?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.case_records.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="总监绩效：">
                    新增：<span>{{ todayGrowthMap.perf_dept_infos?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.perf_dept_infos.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="刊例提报：">
                    新增：<span>{{ todayGrowthMap.publication_items?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.publication_items.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="红人分成：">
                    新增：<span>{{ todayGrowthMap.artist_divides?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.artist_divides.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="红人资料：">
                    新增：<span>{{ todayGrowthMap.kol_infos?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.kol_infos.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="OA审批：">
                    新增：<span>{{ todayGrowthMap.process_instances?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.process_instances.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="红人询单：">
                    新增：<span>{{ todayGrowthMap.inquiry_projects?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.inquiry_projects.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="直播排期：">
                    新增：<span>{{ todayGrowthMap.lb_schedules?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.lb_schedules.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="产品审核：">
                    新增：<span>{{ todayGrowthMap.live_product_check?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.live_product_check.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="红人数据模型：">
                    新增：<span>{{ todayGrowthMap.kol_analysis?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.kol_analysis.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="工作排期：">
                    新增：<span>{{ todayGrowthMap.work_schedules?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.work_schedules.all || 0 }}</span> 条
                  </el-form-item>
                  <el-form-item label="项目结款：">
                    新增：<span>{{ todayGrowthMap.settle_projects?.today || 0 }}</span> 条，
                    总计：<span>{{ todayGrowthMap.settle_projects.all || 0 }}</span> 条
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="12" :md="16" :lg="16" :xl="16">
            <NoticePanel/>
          </el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import NoticePanel from '@/pages/notice/NoticePanel'
import PanelGroup from '@/pages/dashboard/PanelGroup'

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
}

export default {
  name: "HomeIndex",
  components: {PanelGroup, NoticePanel, PageHeaderLayout},
  data() {
    return {
      lineChartData: lineChartData.newVisitis,
      todayGrowthMap: {
        trend_data: {},
        revenue_items: {},
        live_infos: {},
        shop_infos: {},
        business_plans: {},
        case_records: {},
        perf_dept_infos: {},
        publication_items: {},
        artist_divides: {},//红人分成
        kol_infos: {},//红人资料
        process_instances: {},//oa审批
        inquiry_projects: {},//询单
        lb_schedules: {},//直播排期
        live_product_check: {},//产品审核
        kol_analysis: {},//红人数据模型
        work_schedules: {},//工作排期
        settle_projects:{}//项目结款
      },
      loading: false,
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type]
    },
    async loadTodayDateGrowth() {

      this.loading = true
      let {map} = await this.$api.getBusinessDataGrowth()
      this.todayGrowthMap = map
      this.loading = false
    }
  },
  created() {
    this.loadTodayDateGrowth()
  }
}
</script>

<style scoped>

</style>
