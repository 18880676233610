<template>
  <el-row :gutter='40' class='panel-group'>
    <el-col :xs='12' :sm='12' :lg='6' class='card-panel-col'>
      <div class='card-panel' @click="handleSetLineChartData('newVisitis')">
        <div class='card-panel-icon-wrapper icon-people'>
          <!--          <svg-icon icon-class="peoples" class-name="card-panel-icon" />-->
          <i class='el-icon-user-solid card-panel-icon'></i>
        </div>
        <div class='card-panel-description'>
          <div class='card-panel-text' title='今日访客'>
            访客
          </div>
          <count-to :start-val='0' :end-val='visitor_total' :duration='2600' class='card-panel-num' />
        </div>
      </div>
    </el-col>
    <el-col :xs='12' :sm='12' :lg='6' class='card-panel-col'>
      <div class='card-panel' @click="handleSetLineChartData('newVisitis')">
        <div class='card-panel-icon-wrapper icon-message'>
          <!--          <svg-icon icon-class="peoples" class-name="card-panel-icon" />-->
          <i class='el-icon-s-check card-panel-icon'></i>
        </div>
        <div class='card-panel-description'>
          <div class='card-panel-text' title='新增用户'>
            新增系统用户
          </div>
          <count-to :start-val='0' :end-val='today_count' :duration='2600' class='card-panel-num' />
        </div>
      </div>
    </el-col>
    <el-col :xs='12' :sm='12' :lg='6' class='card-panel-col'>
      <div class='card-panel' @click="handleSetLineChartData('newVisitis')">
        <div class='card-panel-icon-wrapper icon-message'>
          <!--          <svg-icon icon-class="peoples" class-name="card-panel-icon" />-->
          <i class='el-icon-s-check card-panel-icon'></i>
        </div>
        <div class='card-panel-description'>
          <div class='card-panel-text' title='系统用户'>
            系统用户
          </div>
          <count-to :start-val='0' :end-val='user_count' :duration='2600' class='card-panel-num' />
        </div>
      </div>
    </el-col>
    <el-col :xs='12' :sm='12' :lg='6' class='card-panel-col'>
      <div class='card-panel' @click="handleSetLineChartData('newVisitis')">
        <div class='card-panel-icon-wrapper icon-people'>
          <!--          <svg-icon icon-class="peoples" class-name="card-panel-icon" />-->
          <i class='el-icon-s-check card-panel-icon'></i>
        </div>
        <div class='card-panel-description'>
          <div class='card-panel-text' title='微信用户'>
            微信用户（招商小程序）
          </div>
          <count-to :start-val='0' :end-val='wx_user_count' :duration='2600' class='card-panel-num' />
        </div>
      </div>
    </el-col>
    <el-col :xs='12' :sm='12' :lg='6' class='card-panel-col'>
      <div class='card-panel' @click="handleSetLineChartData('newVisitis')">
        <div class='card-panel-icon-wrapper icon-people'>
          <!--          <svg-icon icon-class="peoples" class-name="card-panel-icon" />-->
          <i class='el-icon-s-check card-panel-icon'></i>
        </div>
        <div class='card-panel-description'>
          <div class='card-panel-text' title=' KOC用户'>
            KOC结款用户（公众号）
          </div>
          <count-to :start-val='0' :end-val='wx_official_user_count' :duration='2600' class='card-panel-num' />
        </div>
      </div>
    </el-col>
    <!--    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">-->
    <!--      <div class="card-panel" @click="handleSetLineChartData('messages')">-->
    <!--        <div class="card-panel-icon-wrapper icon-message">-->
    <!--          &lt;!&ndash;          <svg-icon icon-class="message" class-name="card-panel-icon" />&ndash;&gt;-->
    <!--          <i class="el-icon-s-comment card-panel-icon"></i>-->
    <!--        </div>-->
    <!--        <div class="card-panel-description">-->
    <!--          <div class="card-panel-text">-->
    <!--            消息-->
    <!--          </div>-->
    <!--          <count-to :start-val="0" :end-val="81212" :duration="3000" class="card-panel-num"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">-->
    <!--      <div class="card-panel" @click="handleSetLineChartData('purchases')">-->
    <!--        <div class="card-panel-icon-wrapper icon-money">-->
    <!--          &lt;!&ndash;          <svg-icon icon-class="money" class-name="card-panel-icon" />&ndash;&gt;-->
    <!--          <i class="el-icon-money card-panel-icon"></i>-->
    <!--        </div>-->
    <!--        <div class="card-panel-description">-->
    <!--          <div class="card-panel-text">-->
    <!--            金额-->
    <!--          </div>-->
    <!--          <count-to :start-val="0" :end-val="9280" :duration="3200" class="card-panel-num"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-col>-->
    <!--    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">-->
    <!--      <div class="card-panel" @click="handleSetLineChartData('shoppings')">-->
    <!--        <div class="card-panel-icon-wrapper icon-shopping">-->
    <!--          &lt;!&ndash;          <svg-icon icon-class="shopping" class-name="card-panel-icon" />&ndash;&gt;-->
    <!--          <i class="el-icon-shopping-cart-full card-panel-icon"></i>-->
    <!--        </div>-->
    <!--        <div class="card-panel-description">-->
    <!--          <div class="card-panel-text">-->
    <!--            订单-->
    <!--          </div>-->
    <!--          <count-to :start-val="0" :end-val="13600" :duration="3600" class="card-panel-num"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-col>-->
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  components: {
    CountTo
  },
  data() {
    return {
      visitor_total: 0,
      today_count: 0,
      user_count: 0,
      wx_user_count: 0,
      wx_official_user_count: 0
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    },
    async loadData() {
      let { visitor_total } = await this.$api.getVisitorData()
      this.visitor_total = visitor_total
    },
    async loadDingTalkUserCount() {
      let { today_count, user_count, wx_user_count, wx_official_user_count } = await this.$api.statisticsUserUser()
      this.today_count = today_count
      this.user_count = user_count
      this.wx_user_count = wx_user_count
      this.wx_official_user_count = wx_official_user_count
    }

  },
  created() {
    this.loadData()
    this.loadDingTalkUserCount()
  }
}
</script>

<style lang='scss' scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px 26px 26px 0;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
